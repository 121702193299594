<template>
    <div class="container min-height">
        <img src="../assets/image/1694.png" height="64" alt="" />
        <div class="width-100 main flex-row justify-content-around align-items-center">
            <div class="width-33 padding-lr left-box">
                <p class="fs-super-big fw-mid black margin-bs">欢迎登录</p>
                <!-- <p class="grey fs-mid line-height2 margin-b">如有问题咨询服务热线：0577-65812717</p> -->
                <p class="grey fs-mid line-height2 margin-b">如有问题咨询服务热线：13957349092</p>
                <div class="padding-Tb-20"></div>
                <div class="center-box flex-column justify-content-center align-items-center">
                    <template v-if="methods == 1">
                        <el-input v-model="mobile" class="margin-b margin-t" placeholder="请输入手机号"></el-input>
                        <div class="width-100 br-reg border margin-b margin-t flex-row justify-content-spaceBetween align-items-center">
                            <el-input v-model="code" class="password width-50" placeholder="请输入验证码"></el-input>
                            <p class="blue width-50 textAlign-r margin-r" style="cursor: pointer" @click="getCode()">{{ num ? `${num}s` : '点击获取验证码' }}</p>
                        </div>

                        <div class="width-100 margin-b margin-t flex-row justify-content-spaceBetween align-items-star">
                            <el-checkbox v-model="checked" class="line-height2" />
                            <p class="width-100 margin-l black line-height2">我已阅读并同意<span class="blue pointer">《瑞安版权直通车服务协议》</span>和<span class="blue pointer">《瑞安版权直通车隐私服务协议》</span></p>
                        </div>
                        <el-button class="width-100 margin-t margin-b" @click="login()">立即登录</el-button>
                    </template>
                    <template v-else>
                        <div class="ewm">
                            <vue-qr :text="qrcode"></vue-qr>
                        </div>
                        <p class="width-100 textAlign-c black line-height" style="margin-bottom: 50px">请打开 <span class="blue pointer">浙里办APP</span> 扫码登录</p>
                    </template>
                </div>

                <!-- <el-divider v-if="methods == 1" class="pointer" @click="changeLoginMethods(2)"><i class="el-icon-full-screen margin-r fs-big blue"></i><span class="blue fs-mid">扫码登录</span></el-divider> -->
                <!-- <el-divider v-else class="pointer" @click="methods = 1"><i class="el-icon-mobile-phone margin-r fs-big blue"></i><span class="blue fs-mid">验证码登录</span></el-divider> -->
            </div>
            <div class="width-33 swiper-right">
                <el-carousel indicator-position="outside">
                    <!-- <el-carousel-item>
                        <div class="width-100 block box-shadow bg-white br-reg margin-b flex-row justify-content-spaceBetween align-items-center">
                            <img src="../assets/image/1696.png" height="100" alt="" />
                            <div class="width-70">
                                <p class="fs-mid-big fw-mid black">作品登记</p>
                                <p class="grey fs-big margin-ts">版权局线上数智化登记审核平台</p>
                            </div>
                        </div>
                        <div class="width-100 block box-shadow bg-white br-reg margin-b flex-row justify-content-spaceBetween align-items-center">
                            <div class="width-70">
                                <p class="fs-mid-big fw-mid black">金融通道</p>
                                <p class="grey fs-big margin-ts">知识产权金融创新，开放型数 字化的金融服务矩阵</p>
                            </div>
                            <img src="../assets/image/1695.png" height="100" alt="" />
                        </div>
                        <div class="width-100 block box-shadow bg-white br-reg margin-b flex-row justify-content-spaceBetween align-items-center">
                            <img src="../assets/image/1697.png" height="100" alt="" />
                            <div class="width-70">
                                <p class="fs-mid-big fw-mid black">交易服务</p>
                                <p class="grey fs-big margin-ts">链上版权流转，透明，快捷，公平</p>
                            </div>
                        </div>
                    </el-carousel-item> -->
                    <el-carousel-item style="display: flex;justify-content: center;">
                        <img src="../assets/image/1801.png" height="100%" alt="" />
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="width-100 block box-shadow bg-white br-reg margin-bl flex-row justify-content-spaceBetween align-items-center">
                            <div class="width-70">
                                <p class="fs-mid-big fw-mid black">作品登记</p>
                                <p class="grey fs-big margin-ts">版权局线上数智化登记审核平台</p>
                            </div>
                            <img src="../assets/image/1696.png" height="100" alt="" />
                        </div>
                        <div class="width-100 block box-shadow bg-white br-reg margin-bl flex-row justify-content-spaceBetween align-items-center">
                            <img src="../assets/image/1263.png" height="100" alt="" />
                            <div class="width-70">
                                <p class="fs-mid-big fw-mid black">维权通道</p>
                                <p class="grey fs-big margin-ts">知名律所、仲裁、司法鉴定 机构入驻，公平高效</p>
                            </div>
                        </div>
                        <div class="width-100 block box-shadow bg-white br-reg flex-row justify-content-spaceBetween align-items-center">
                            <div class="width-70">
                                <p class="fs-mid-big fw-mid black">版权示范创建</p>
                                <p class="grey fs-big">提升城市、单位和园区(基地) 自主创新能力，推动版权保护 工作更好地为经济建设服务</p>
                            </div>
                            <img src="../assets/image/1265.png" height="100" alt="" />
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <p class="white textAlign-r margin-b fs-mid line-height2 margin-r">其他功能请登录浙里办，定位瑞安，搜索版权登记。</p>
            </div>
        </div>
        <p class="sign-off textAlign-c grey line-height2" @click="verifyQrcodeLogin">
            中共瑞安市委宣传部（瑞安市新闻出版局）©版权所有<br />
            瑞安市数据管理发展有限公司 技术支持
        </p>
    </div>
</template>
<script>
import vueQr from 'vue-qr';
import { getQrcode, verifyQrcodeLogin, getSms, smsLogin } from '@/api/pcLoginRecord';
// import { getZLBVerify } from '@/util/zlb';

export default {
    name: 'Login',
    components: {
        vueQr
    },
    data() {
        return {
            mobile: '',
            code: '',
            num: 0,
            checked: false,
            methods: 1, // 1-手机号登录，2-扫码登录
            qrcode: '',
            interval: null
        };
    },
    created() {
        if (this.$route.query.infoMsg) {
            localStorage.setItem('bqsb_login', this.$route.query.infoMsg);
            location.href = 'https://bqsb.rarb.cn/web/#/index';
            return;
        }
        setInterval(() => {
            if (this.num) this.num--;
        }, 1000);
    },
    mounted() {},
    methods: {
        async verifyQrcodeLogin() {
            await verifyQrcodeLogin({ random: this.qrcode }).then((res) => {
                if (res.msg) {
                    localStorage.setItem('bqsb_login', res.msg);
                    this.$router.push({ path: '/index' });
                    clearInterval(this.interval);
                }
            });
        },
        async getQrcode() {
            await getQrcode().then((res) => {
                this.qrcode = res.msg;
            });
            // const a = getZLBVerify();
            // console.log(a);
        },
        async changeLoginMethods(i) {
            await this.getQrcode();
            this.startInterval();
            this.methods = i;
        },
        startInterval() {
            this.interval = setInterval(async () => {
                await this.verifyQrcodeLogin();
            }, 2000);
        },
        async getCode() {
            if (this.mobile.length === 0) return;
            if (this.num > 0) return;
            this.num = 60;
            await getSms(this.mobile);
        },
        async login() {
            if (!this.mobile || this.mobile.length < 4) {
                this.$alert('请输入正确的手机号，并获取验证码！', '提示', {
                    confirmButtonText: '确定',
                    callback: () => {}
                });
                return;
            }
            if (!this.code || this.code.length < 5) {
                this.$alert('请输入正确的验证码！', '提示', {
                    confirmButtonText: '确定',
                    callback: () => {}
                });
                return;
            }
            if (!this.checked) {
                this.$alert('请勾选服务协议！', '提示', {
                    confirmButtonText: '确定',
                    callback: () => {}
                });
                return;
            }
            await smsLogin(this.mobile, this.code).then((res) => {
                const reg = /[\u3400-\u9fa5]/;
                if (res.code !== 200) {
                    this.$message.error(res.msg);
                } else if (reg.test(res.token)) {
                    this.$message.error('token错误');
                } else if (res.msg === '操作成功') {
                    localStorage.setItem('bqsb_login', res.msg);
                    if (res.token) localStorage.setItem('bqsb_login', res.token);
                    this.$router.push({ path: '/index' });
                    clearInterval(this.interval);
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.container {
    background: url('../assets/image/11.png') no-repeat right 0;
    background-size: contain;
    padding: 80px 5%;
    .main {
        margin-top: 40px;
        .password {
            /deep/.el-input__inner {
                border: none;
            }
        }
    }
    .el-button {
        background: #007aff;
        border: none;
        color: white;
    }
    /deep/.el-input__inner {
        padding: 28px 32px;
        font-size: 1.2rem;
    }
    .sign-off {
        position: fixed;
        bottom: 50px;
        width: 100%;
    }
    .left-box {
        height: 560px;
    }
    .swiper-right {
        /deep/.el-carousel__container {
            height: 560px;
        }

        /deep/.el-carousel__indicator .el-carousel__button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            opacity: 0;
            background: rgba(255, 255, 255, 0.8);
        }
        /deep/.el-carousel__indicator.is-active button {
            width: 40px;
            height: 12px;
            border-radius: 6px;
            background: rgba(255, 255, 255, 1);
        }
        .el-carousel__item {
            padding: 20px;
            .block {
                padding: 20px 40px;
            }
        }
    }
    .ewm {
        width: 200px;
        height: 200px;
        margin: 10px auto 20px;
    }
    .center-box {
        height: 360px;
    }
}
.el-button {
    padding: 1.25rem 1.25rem;
}
</style>
