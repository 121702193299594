import request from '@/util/request';

export function getQrcode(query) {
    return request({
        url: '/bqsb/pcLoginRecord/qrcode',
        method: 'get',
        params: query
    });
}

export function verifyQrcodeLogin(data) {
    return request({
        url: '/bqsb/pcLoginRecord/verifyQrcodeLogin',
        method: 'post',
        data: data
    });
}

export function smsLogin(tel, code) {
    return request({
        url: `/bqsb/pcLoginRecord/smsLogin/${tel}/${code}`,
        method: 'post',
        data: {}
    });
}

export function getSms(tel) {
    return request({
        url: `/bqsb/pcLoginRecord/sms/${tel}`,
        method: 'get'
    });
}
